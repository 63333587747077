import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { toast } from "react-toastify";
import { CustomToast } from "../customtoast/CustomToast";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// import DataUsageIcon from '@mui/icons-material/DataUsage';
// import BuildIcon  from '@mui/icons-material/Build ';
import SelectableCheckbox from "../AssetPage/CheckBox";
import { AuthContext } from "../../AuthContext";
import api from "../../axios";
// import MainContext from "../../contexts/MainContext";
import { AddCircle } from "@mui/icons-material";

const assetType = ({type}) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <AddCircleOutlineIcon />
      <Typography variant="body2">Datum</Typography>
    </Box>
  );
};

export default function AddTotalizerModal({ asset }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  // const {getAssetData} = React.useContext(MainContext)

  const handleClose = () => {
    setData({
      company_id: token.id,
      reading: "",
      image:'',
      asset:asset
    });
    setOpen(false);
  };

  const isMobile = useMediaQuery("(max-width: 600px)");

  const { token } = React.useContext(AuthContext);
  const company_id = token.company_id?token.company_id:token.id

  const [data, setData] = React.useState({
    company_id: company_id,
    reading: "",
    image:'',
    asset:asset
  });



  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90vw" : "50vw",
    maxHeight: "80vh",
    bgcolor: colors.background[900],
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "auto",
  };

  const [error, setError] = React.useState(null);

  const handleChange = (event,type) => {
    if (type==='image'){
      setData((prevData) => ({ ...prevData, ['image']: event.target.files[0], }));
    }else{
      setData((prevData) => ({ ...prevData, ['reading']: event.target.value, }));
    }

    console.log('====================================');
    console.log('eeeeeeeeeeeeeee',data);
    console.log('====================================');
  };



  const handleSubmit = (e) => {
    e.preventDefault()
const formData = new FormData();
  formData.append("company", company_id);
  formData.append("single_reading", data.reading);
  formData.append("asset", asset);
  formData.append("image", data.image);
  formData.append('added_by',token.id)
      api
        .post("totalizer", formData)
        .then((response) => {
        //  updateData();
        // getAssetData()
          handleClose();
        })
        .catch((error) => {
          
          const errorMessages = error.response.data;
          const keys = Object.keys(errorMessages);
          const values = Object.values(errorMessages);
          console.log('====================================');
          console.log('dddd',errorMessages);
          console.log('===================================='); 
          // toast.error(<CustomToast keys={keys} values={values}/>);
          if (error.response.status === 403){
            window.location.reload()
          }
        });
  };

  // const { assetData } = React.useContext(MainContext);

  //   React.useEffect(() => {

  //   }, [])

  return (
    <div>
       <Button onClick={handleOpen} color='warning' variant='contained' sx={{width:'100%',mb:1,display:'flex',justifyContent:'çenter',alignItems:''}}><AddCircle sx={{mb:0.1,mr:0.6}}/> Add Totalizer</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
      <div style={style} className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg">
  <h3 className="text-3xl text-center mb-6">Add Totalizer Details</h3>
  <div className="flex justify-end">
    <p className="mr-2 cursor-pointer" onClick={handleClose}>
      Close
    </p>
    <CloseIcon className="cursor-pointer" onClick={handleClose} />
  </div>

  <form>
    <div className="mb-4">
      <label htmlFor="username" className="block mb-2 font-bold">
        Enter The Reading
      </label>
      <input
        id="reading"
        name="reading"
        value={data.reading}
        className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
        onChange={(event)=>handleChange(event,'reading')}
      />
    </div>

  <div className="mb-4">
    <label htmlFor="image" className="block mb-2 font-bold">
      Upload Image
    </label>
    <input
      id="image"
      name="image"
      type="file"
      accept="image/*"
      className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
      onChange={(event)=>handleChange(event,'image')}
    />
  </div>

   

    <div className="flex justify-center">
      <button
        // type="submit"
        onClick={handleSubmit}
        className="px-6 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700"
      >
        Submit
      </button>
    </div>
  </form>
</div>

      </Modal>
    </div>
  );
}
