import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import { mockDataTeam } from "../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../components/Header";
import AddCompanyModal from "../components/Modals/AddCompanyModal";
import BasicTable from "../components/Tables/BasicTable";
import axios from "axios";
import { useEffect, useState } from "react";
import { getRoles } from "@testing-library/react";
import api from "../axios";
import { useContext } from "react";
import { AuthContext } from "../AuthContext";
import AssetsBox from "../components/AssetPage/AssetsBox";
import AddAssetModal from "../components/AssetPage/AddAssetModal";
import { useLocation, useParams } from "react-router-dom";
import DeliveryDetailsBox from "../components/OrderDetailsPage/DeliveryDetailsBox";
import OrderDetailsBox from "../components/OrderDetailsPage/OrderDetailsBox";

const OrderDetails = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { orderId } = useParams();
  const location = useLocation()
  console.log('====================================');
  console.log(location.state);
  console.log('====================================');


  // const { rowData, setRowData, getRows } = useContext(AuthContext);

  const [rowData, setRowData] = useState({});

  const getRows = async () => {
    await api
      .get(`order-details/${orderId}`)
      .then((response) => {
        console.log("11111111444444", response.data);
        setRowData(response.data);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          window.location.reload();
        }
      });
  };

  useEffect(() => {
    getRows();
  }, []);

  return (
    <Box sx={{ marginInline: "20px" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {/* <Header title="Order Details" subtitle="" /> */}
        <OrderDetailsBox orderData={rowData} />
        {/* <Box> */}
            {/* <AddAssetModal updateTable={getRows} /> */}
            {/* </Box> */}
      </Box>

      <Box>
        {/* <BasicTable rows={rowData} updateTable={getRows}></BasicTable> */}
        {/* <AssetsBox data={rowData} /> */}
        {/* <Box 
        className="h-20 bg-white"
        >
            
            </Box> */}

        <DeliveryDetailsBox orderData={rowData}/>
      </Box>
    </Box>
  );
};

export default OrderDetails;
