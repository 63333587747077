import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import api from "../../axios";
import { CheckIcon } from "@heroicons/react/20/solid";
import { Info } from "@mui/icons-material";
import { toast } from "react-toastify";
// import { toast } from "react-toastify";

const CompleteDeliveryModal = ({ orderId, deliveredQty, approvedQty }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [isBillable, setIsBillable] = useState(null);

  const handleCompleteOrder = () => {
    if (isBillable == null){
        toast.error('Select Order is Billable or Not...!')
        return
    }
    console.log('====================================');
    console.log(isBillable);
    console.log('====================================');
    // Put your logic here for completing the order
    // For example, you can make an API call to mark the order as completed
    // and then close the modal
    // api.completeOrder(orderId).then(() => handleClose());
    api
      .post(`complete-order/${orderId}`, { is_billable: isBillable })
      .then((response) => {
        toast.success(response.data);
        window.location.reload()
      })
      .catch((error) => {
        if (error && error.response && error.response.data && typeof error.response.data === 'string') {
      toast.error(error.response.data);
    } else {
      toast.error('Something went wrong');
    }
      })
      ;

    handleClose();
  };

  return (
    <div>
      <span className="sm:ml-3">
        <button
          onClick={handleOpen}
          type="button"
          className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <CheckIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          Complete Order
        </button>
      </span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            minWidth: 400,
          }}
        >
          <div className="text-center">
            <p className="text-2xl font-semibold">Confirm Order Completion</p>
            <p className="text-lg font-semibold mt-2">
              Approved Quantity : {approvedQty} Ltrs
            </p>
            <p className="text-lg font-semibold">
              Delivered Quantity : {deliveredQty} Ltrs
            </p>

            {approvedQty !== deliveredQty ? (
              <p className="text-md text-red-500 font-semibold">
                <Info /> Approved Quantity is not equal to Delivered Quantity
              </p>
            ) : (
              " "
            )}

            <div className="mt-2">
                <p>Is this Order Billable..?</p>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="billable"
                  value="yes"
                  className="form-radio h-5 w-5 text-indigo-600"
                  checked={isBillable}
                  onChange={() => setIsBillable(true)}
                />
                <span className="ml-2">Yes</span>
              </label>
              <label className="inline-flex items-center ml-4">
                <input
                  type="radio"
                  name="billable"
                  value="no"
                  className="form-radio h-5 w-5 text-indigo-600"
                  checked={isBillable === false}
                  onChange={() => setIsBillable(false)}
                />
                <span className="ml-2">No</span>
              </label>
            </div>

            <p className="text-gray-600 mt-2">
              Are you sure you want to complete this order?
            </p>
            <div className="mt-4">
              <button
                onClick={handleCompleteOrder}
                className="px-4 py-2 mr-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-500 focus:outline-none focus:ring focus:ring-indigo-200"
              >
                Yes
              </button>
              <button
                onClick={handleClose}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-200 focus:outline-none focus:ring focus:ring-gray-200"
              >
                No
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default CompleteDeliveryModal;
