import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  ButtonGroup,
  List,
  ListItem,
  ListItemText,
  Pagination,
  useTheme,
  Card,
  CardContent,
  useMediaQuery,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import api from "../../axios";
import { tokens } from "../../theme";
import RecommendIcon from "@mui/icons-material/Recommend";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

import CreditScoreIcon from "@mui/icons-material/CreditScore";

import { AuthContext } from "../../AuthContext";

import AddPaymentModal from "./AddPayment";

const TransactionBox = ({ updateTable, company,outstanding_amount }) => {
  const [transactions, setTransactions] = useState([]);
  const [paymentType, setPaymentType] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const {selectedCompany} = useContext(AuthContext)
  const company_id = selectedCompany.id
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isMobile = useMediaQuery("(max-width : 600px)");
  useEffect(() => {
    fetchPayments();
  }, [paymentType, currentPage]);

  let dummyIcon = require("../../assets/datum.png");

  // const {token} = useContext(AuthContext)
  const fetchPayments = async () => {
    try {
      const response = await api.get('transactions', {
        params: {
          payment_type: paymentType,
          page: currentPage,
          company_id: company_id,
        },
      });
      console.log(response.data);
      const { results, current_page, count } = response.data;
      setTransactions(results);
      //   setCurrentPage(current_page);
      setTotalPages(parseInt(count / 12) + 1);
    } catch (error) {
      console.error("Error fetching order history:", error);
    }

    // api.get('populateorder')
  };

  const handleFilterChange = (status) => {
    setPaymentType(status);
    setCurrentPage(1);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    console.log(totalPages);
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleString("en-US", options);
  };

  return (
    <div>
      <div style={{ marginBottom: "10px" }}>
        <AddPaymentModal updateTable={updateTable} company={company_id} fetchPayments={fetchPayments}/>
      </div>

<Typography variant='h3' sx={{mb:2}}>
  All Transactions
</Typography>

    <Box display='flex' flexDirection={isMobile?'column-reverse':'row'} justifyContent='space-between'>
      <Stack direction={isMobile ? "column" : "row"} spacing={1}>
        <ButtonGroup
          sx={{
            width:isMobile ? "56vw" : "auto",
            marginBottom: isMobile ? "8px" : 0,
          }}
          size={isMobile ? "small" : "large"}
          variant="contained"
        >
          <Button
            sx={{
              bgcolor:
                paymentType === "" ? colors.card["hover"] : colors.card["bg"],
              color: colors.pure[900],
              "&:hover": {
                backgroundColor: colors.card["hover"],
              },
            }}
            onClick={() => handleFilterChange("")}
            startIcon={<FormatListBulletedIcon />}
          >
            All
          </Button>
          <Button
            sx={{
              bgcolor:
                paymentType === "purchase"
                  ? colors.card["hover"]
                  : colors.card["bg"],
              color: colors.pure[900],
              "&:hover": {
                backgroundColor: colors.card["hover"],
              },
            }}
            onClick={() => handleFilterChange("purchase")}
            startIcon={<RecommendIcon />}
          >
            Purchases
          </Button>
          <Button
            sx={{
              bgcolor:
                paymentType === "paid"
                  ? colors.card["hover"]
                  : colors.card["bg"],
              color: colors.pure[900],
              "&:hover": {
                backgroundColor: colors.card["hover"],
              },
            }}
            onClick={() => handleFilterChange("paid")}
            startIcon={<CreditScoreIcon />}
          >
            Paid
          </Button>

          {/* {!isMobile ? (
            <>
              <Button
                sx={{
                  bgcolor:
                    orderStatus === "payment_pending"
                      ? colors.card["hover"]
                      : colors.card["bg"],
                  color: colors.pure[900],
                  "&:hover": {
                    backgroundColor: colors.card["hover"],
                  },
                }}
                onClick={() => handleFilterChange("payment_pending")}
                startIcon={<PendingActionsIcon />}
              >
                Payment Pending
              </Button>
              <Button
                sx={{
                  bgcolor:
                    orderStatus === "payment_complete"
                      ? colors.card["hover"]
                      : colors.card["bg"],
                  color: colors.pure[900],
                  "&:hover": {
                    backgroundColor: colors.card["hover"],
                  },
                }}
                onClick={() => handleFilterChange("payment_complete")}
                startIcon={<CreditScoreIcon />}
              >
                Payment Complete
              </Button>
            </>
          ) : (
            ""
          )} */}
        </ButtonGroup>
        {/* {isMobile && (
          <ButtonGroup
            sx={{
              width: "65vw",
            }}
            size="small"
            variant="contained"
          >
            <Button
              sx={{
                bgcolor:
                  orderStatus === "payment_pending"
                    ? colors.card["hover"]
                    : colors.card["bg"],
                color: colors.pure[900],
                "&:hover": {
                  backgroundColor: colors.card["hover"],
                },
              }}
              onClick={() => handleFilterChange("payment_pending")}
              startIcon={<PendingActionsIcon />}
            >
              Payment Pending
            </Button>
            <Button
              sx={{
                bgcolor:
                  orderStatus === "payment_complete"
                    ? colors.card["hover"]
                    : colors.card["bg"],
                color: colors.pure[900],
                "&:hover": {
                  backgroundColor: colors.card["hover"],
                },
              }}
              onClick={() => handleFilterChange("payment_complete")}
              startIcon={<CreditScoreIcon />}
            >
              Payment Done
            </Button>
          </ButtonGroup>
        )} */}
      </Stack>

      <Typography variant='h5' fontWeight='600'>
        Total Outstanding Amount :<br></br> ₹ {outstanding_amount}
      </Typography>

      </Box>

      {/* <List sx={{ height: "57vh",marginBlock:2,pt:0, overflow: "auto" }}>
        {orders.map((order) => (
          <ListItem key={order.id}> */}
      {/* Display order details here */}
      {/* <ListItemText primary={`Order ID: ${order.id}`} />
            <ListItemText primary={`Quantity: ${order.quantity}`} /> */}
      {/* Display other order fields as needed */}
      {/* </ListItem>
        ))}
      </List> */}

      <List sx={{ height: "57vh", marginBlock: 2, pt: 0, overflow: "auto" }}>
        {transactions.map((obj) => (
          <Card
            key={obj.id}
            variant="outlined"
            sx={{ marginBottom: 2, bgcolor: colors.card2["bg"] }}
          >
            <CardContent>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingRight: isMobile ? "" : "50px",
                }}
              >
                <div>
                  {/* <Box display="flex">
                    <img
                      src={dummyIcon}
                      alt='icon'
                      style={{
                        width: "40px",
                        height: "40px",
                        marginRight: "10px",
                        filter:colors.pngcol['filter']
                      }}
                    />
                    <Box display="flex" flexDirection="column">
                      <Typography fontWeight="700">
                        Asset Name
                      </Typography>
                      <Typography>
                        Datum
                      </Typography>
                    </Box>
                  </Box> */}

                  <div style={{ marginTop: "5px" }}>
                    <Typography fontWeight="600">
                      Transaction ID:{" "}
                      <span style={{ fontWeight: "700", fontSize: "15px" }}>
                        {obj.id}
                      </span>
                    </Typography>

                    {/* <Typography fontWeight="600" fontSize={isMobile?'13px':'15px'}>
                      Payment Status : 
                      <span style={{ fontWeight: "700", fontSize: isMobile?'13px':'16px' }}>
                        Pending
                      </span>
                    </Typography> */}

                    <Typography
                      fontWeight="600"
                      fontSize={isMobile ? "13px" : "15px"}
                    >
                      Created At:{" "}
                      <span
                        style={{
                          fontWeight: "700",
                          fontSize: isMobile ? "13px" : "16px",
                        }}
                      >
                        {!isMobile ? formatDate(obj.created_at) : ""}
                      </span>
                    </Typography>

                    {isMobile ? (
                      <Typography>{formatDate(obj.created_at)}</Typography>
                    ) : (
                      ""
                    )}

                    {/* 
                    {`Created At: ${order.created_at}`}
                    </Typography> */}
                    {/* <Typography>
                    {`Order ID: ${order.id}`}
                    </Typography> */}
                    {/* <ListItemText primary={`Created At: ${order.created_at}`} /> */}
                    {/* <ListItemText primary={`Asset Name: ${order.asset.name}`} />
                    <ListItemText primary={`Asset Type: ${order.asset.type}`} /> */}
                  </div>
                </div>
                <div style={{ minWidth: "140px" }}>
                  {/* <Typography
                    sx={{
                      fontWeight: "700",
                      fontSize: isMobile?'14px':'16px',
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <OpacityIcon style={{fontWeight:'600',fontSize:'16px'}} />
                    <span style={{fontWeight:'600'}}> Quantity :&nbsp;</span> 550 ltrs Ltrs
                  </Typography> */}

                  {/* <Typography
                    sx={{
                      fontWeight: "700",
                      fontSize: isMobile?'14px':'16px',
                      display: "flex",
                      alignItems: "center",
                      mt:0.5,
                      mb:0.5
                    }}
                  >
                    <LocalGasStationIcon style={{fontWeight:'600',fontSize:'16px'}} />
                    <span style={{fontWeight:'600'}}>Diesel Price :&nbsp;</span>  ₹ 454545
                  </Typography> */}

                  <Box
                    sx={{
                      fontWeight: "700",
                      fontSize: !isMobile ? "18px" : "15px",
                      display: "flex",
                      alignItems: "center",
                      p: 0.3,
                      borderRadius: 1,
                      //   boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                      border: `1px solid ${colors.grey[700]}`,
                      paddingInline: 0.7,
                      mb: 1,
                      color:
                        obj.payment_type === "purchase" ? "crimson" : "green",
                      //   bgcolor:colors.pure[900],
                      //   color:colors.pure[100]
                    }}
                  >
                    <span style={{ fontWeight: "600" }}>
                      {" "}
                      {obj.payment_type === "purchase"
                        ? "Purchase"
                        : "Paid"}{" "}
                      &nbsp;
                    </span>
                    :&nbsp;₹ {obj.payment_price}
                  </Box>

                  {/* <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'2px'}}>
                    <Typography sx={{textAlign:'center',fontSize:'16px',color:'green',fontWeight:'600'}}>
                        Approved
                    </Typography>
                  <Button variant="contained" size='small' color="primary">
                    Cancel
                  </Button>

                  </div> */}
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </List>

      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        showFirstButton
        showLastButton
        siblingCount={2}
        boundaryCount={2}
      />
    </div>
  );
};

export default TransactionBox;
