import { useState } from "react";
import { Routes, Route } from "react-router-dom";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import UnderDev from "./progres/UnderDev";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import AdminContainer from "./AdminContainer";
import UserContainer from "./UserContainer";
import LoginPage2 from "./Pages/Auth/LoginPage";
import PrivateRoute from "./PrivateRouter";
import { AuthProvider } from "./AuthContext";
import UserLogin from './User/UserLogin';
import ServerDown from "./ServerDown";
import './styles.css';
import "react-confirm-alert/src/react-confirm-alert.css";


function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
    <AuthProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
          <Routes>
            {/* <Route path="/login" element={<UserLogin />} /> */}
            <Route path="/login" element={<LoginPage2 />} />
            <Route path="/serverdown" element={<ServerDown />} />
            {/* <Route path="/" element={<PrivateRoute path="/" component={UserContainer} />} /> */}
            <Route

              path="/*"
              element={<PrivateRoute path="/*" component={AdminContainer} />}
            />
          </Routes>
          </div>
          <ToastContainer
            style={{
              width: 'auto',
              minWidth: '250px',
              bottom: 0,
              left: '50%',
              transform: 'translateX(-50%)',
            }}
            // autoClose={3000}
          />
        </ThemeProvider>

    </AuthProvider>
    </ColorModeContext.Provider>
  );
}

export default App;