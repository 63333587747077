import React, { useState } from "react";
import {
  Modal,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Box,
  TextField,
  InputLabel,
  OutlinedInput,
  Button,
} from "@mui/material";
import api from "../../axios";
import { useEffect } from "react";
import { toast } from "react-toastify";


const SampleData = {
  orders: [
    { id: 101, location: "Location A", quantity: 5, date: "2023-07-28" },
    { id: 102, location: "Location A", quantity: 3, date: "2023-07-29" },
    { id: 103, location: "Location C", quantity: 8, date: "2023-07-30" },
    // Add more orders here as needed
  ],
};

const AddInvoiceModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDateOption, setSelectedDateOption] = useState("today");
  const [selectedOrder, setSelectedOrder] = useState("");
  const [manualDate, setManualDate] = useState("");
  const [amount, setAmount] = useState("");
  const [filePreviewUrl, setFilePreviewUrl] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [billNo, setBillNo] = useState("");

  const handleOrderChange = (event) => {
    console.log('====================================');
    console.log(event.target.value);
    console.log('====================================');
    setSelectedOrder(event.target.value);
  };

  const handleDateOptionChange = (event) => {
    setSelectedDateOption(event.target.value);
  };

  const handleDateChange = (event) => {
    setManualDate(event.target.value);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("date_option", selectedDateOption);
    formData.append(
      "invoice_date",
      selectedDateOption === "manual"
        ? manualDate
        : new Date().toISOString().slice(0, 10)
    );
    formData.append("order", selectedOrder.id);
    formData.append("amount", amount);
    formData.append("invoice_no", billNo);
    formData.append("invoice_file", selectedFile);
    formData.append('destination',selectedOrder.location.location);
    formData.append('company',selectedOrder.company)
    // formData.append('')

    api
      .post("invoice", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((response)=>{
        toast.success('Success')
        // handleClose();
        window.location.reload()

      }).catch((error)=>{
        // toast.error(error.response.data)
        toast.error('Something Went Wrong..Pls Fill all the field')
      })
      
    
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    if (file && file.type === "application/pdf") {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setFilePreviewUrl("");
    }
  };

  const handleAddPdf = () => {
    setFilePreviewUrl("");
  };



  const [orders,setOrders] = useState([])

  const getOrders =  () => {
     api.get('unbilled-orders').then((response)=>{
        console.log('====================================');
        console.log(response.data);
        console.log('====================================');
        setOrders(response.data)
    }).catch((error)=>{
        console.log('====================================');
        console.log(error.response.data);
        console.log('====================================');
    })
  }

  useEffect(() => {
    getOrders()
  }, [])
  

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    maxHeight: "80vh",
    bgcolor: "white",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "auto",
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Add Invoice
      </Button>

      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={style}>
          <div className="bg-white overflow-auto h-full">
            <h2 className="text-xl font-bold mb-4">Add Invoice</h2>

            <FormControl component="fieldset">
              <FormLabel component="legend">Date Option</FormLabel>
              <RadioGroup
                value={selectedDateOption}
                onChange={handleDateOptionChange}
                row
              >
                <FormControlLabel
                  value="today"
                  control={<Radio />}
                  label="Today"
                />
                <FormControlLabel
                  value="manual"
                  control={<Radio />}
                  label="Manual Date"
                />
              </RadioGroup>
            </FormControl>

            {selectedDateOption === "manual" && (
              <div className="mb-4">
                <input
                  type="date"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  value={manualDate}
                  onChange={handleDateChange}
                />
              </div>
            )}

            <div className="mb-4">
              <InputLabel htmlFor="order">Select Order</InputLabel>
              <Select
                value={selectedOrder}
                onChange={handleOrderChange}
                input={<OutlinedInput label="Select Order" id="order" />}
                className="border border-gray-300 rounded-lg p-2 w-full"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {orders.map((order) => (
                  <MenuItem key={order.id} value={order}>
                   Order : {order.order_id} - {order.company_name} - {order?.location?.location} - {order.delivered_quantity}Ltrs
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div className="mb-4">
              <TextField
                label="Amount"
                type="number"
                value={amount}
                onChange={(event) => setAmount(event.target.value)}
                variant="outlined"
                fullWidth
              />
            </div>

            <div className="mb-4">
              <TextField
                label="Bill No"
                value={billNo}
                onChange={(event) => setBillNo(event.target.value)}
                variant="outlined"
                fullWidth
              />
            </div>

            <div className="mb-4">
              <input
                type="file"
                accept=".pdf"
                onChange={handleFileChange}
                className="border border-gray-300 rounded-lg p-2 w-full"
              />
            </div>

            <Modal open={filePreviewUrl !== ""} onClose={handleAddPdf}>
              <div className="bg-white w-[70vw] p-4 overflow-auto mx-auto mt-10 h-auto rounded-lg">
                <h2 className="text-xl font-bold mb-4">PDF Preview</h2>
                {filePreviewUrl && (
                  <div className="mb-4 overflow-auto">
                    <iframe
                      src={filePreviewUrl}
                      title="PDF Preview"
                      width="100%"
                      height="340px"
                      frameBorder="0"
                      scrolling="auto"
                    />
                  </div>
                )}
                <div className="flex flex-row gap-2">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddPdf}
                    className="w-half bg-green-500"
                  >
                    Add
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setFilePreviewUrl("")}
                    className="w-half bg-red-500"
                  >
                    Close
                  </Button>
                </div>
              </div>
            </Modal>

            <div className="mb-4">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                className="w-full"
              >
                Submit
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AddInvoiceModal;
