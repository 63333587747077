import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, Box, Typography, TextField, ListItemIcon, ListItemText, useMediaQuery } from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import api from "../../axios";
import { toast } from "react-toastify";
import OrderBox from "./OrdersBox";
import { LocalShipping,Payments } from "@mui/icons-material";
import TransactionBox from "./TransactionBox";


const TransactionsModal = ({updateTable, company,outstanding_amount}) => {
  const [open, setOpen] = useState(false);
  const [location, setLocation] = useState("");
  // const [location2,setLocation2] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [data, setData] = useState([])

  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open Modal</Button> */}
      <div onClick={handleOpen} style={{display:'flex',flexDirection:'row'}}> 

      <ListItemIcon><Payments/></ListItemIcon>
      <ListItemText secondary='Payments' />
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            minWidth: isMobile?400:'70vw',
          }}
        >
         
              


          <TransactionBox updateTable={updateTable} outstanding_amount={outstanding_amount} company={company}/>
        



          
          
        </Box>
      </Modal>
    </div>
  );
};

export default TransactionsModal;
