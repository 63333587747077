import { Box, Typography, useTheme, Button } from "@mui/material";
import { tokens } from "../theme";

import { useEffect, useState } from "react";
import api from "../axios";
import { useContext } from "react";
import AddAssetModal from "../components/AssetPage/AddAssetModal";
// import { useLocation, useParams } from "react-router-dom";
import DeliveryDetailsBox from "../components/OrderDetailsPage/DeliveryDetailsBox";
import OrderDetailsBox from "../components/OrderDetailsPage/OrderDetailsBox";
import Header from "../components/NewComponents/Header";
import HeaderButton from "../components/NewComponents/HeaderButton";
import { AddCircleOutline, Description } from "@mui/icons-material";
import { BriefcaseIcon } from "@heroicons/react/20/solid";
import InvoiceTable from "../components/InvoicePage/InvoiceTable";
import AddInvoiceModal from "../components/InvoicePage/AddInvoiceModal";
import AddPaymentModal from "../components/PaymentsPage/AddPaymentModal";
import PaymentsTable from "../components/PaymentsPage/PaymetsTable";

const Buttons = [
  //   <HeaderButton
  //     name="Add Invoice"
  //     icon={<AddCircleOutline />}
  //     // onClick={}
  //   />

  <AddPaymentModal />,
];

const SubDetails = [
  {
    title: "All Payments",
    icon: (
      <BriefcaseIcon
        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
        aria-hidden="true"
      />
    ),
  },
];

const Payments = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  //   const { orderId } = useParams();

  // const { rowData, setRowData, getRows } = useContext(AuthContext);

  const [rowData, setRowData] = useState([]);

  const getRows = async () => {
    await api
      .get("get-payments")
      .then((response) => {
        console.log("11111111444444", response.data);
        setRowData(response.data);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          window.location.reload();
        }
      });
  };

  useEffect(() => {
    getRows();
  }, []);

  const invoiceData = [
    {
      date: "2023-07-25",
      company: "ABC Corp",
      invoiceNo: "INV-001",
      amount: 1000,
      paymentStatus: "Paid",
    },
    {
      date: "2023-07-26",
      company: "XYZ Ltd",
      invoiceNo: "INV-002",
      amount: 1500,
      paymentStatus: "Pending",
    },
    {
      date: "2023-07-27",
      company: "Example Inc",
      invoiceNo: "INV-003",
      amount: 800,
      paymentStatus: "Paid",
    },
    // Add more invoice objects if needed
  ];

  return (
    <Box sx={{ marginInline: "20px" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {/* <Header title="Order Details" subtitle="" /> */}
        <Header title="Payments" buttons={Buttons} subDetails={SubDetails} />
      </Box>

      <Box>
        <PaymentsTable fetch_type='all' paymentData={rowData} />
      </Box>
    </Box>
  );
};

export default Payments;
