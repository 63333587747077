// DownloadButton.js

import React from "react";
import { Button } from "@mui/material";
// import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { DownloadForOfflineTwoTone, DownloadingOutlined } from "@mui/icons-material";




const DownloadInvoiceButton = ({ fileUrl }) => {

    const handleViewPdf = () => {
      const newTab = window.open(fileUrl, "_blank");
      if (newTab) {
        newTab.focus();
      } else {
        // If the new tab was blocked by the browser, show an error message
        console.error("Error opening PDF: Pop-up blocked by the browser.");
      }
    };



  return (
    <Button variant="contained" sx={{bgcolor:'green'}} onClick={handleViewPdf}>
      <DownloadingOutlined/>
    </Button>
  );
};

export default DownloadInvoiceButton;
