import React, { useContext } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import LoadingIndicator from './components/LoadingIndicator';
import { useEffect } from 'react';

const PrivateRoute = ({ path, component:Component , adminOnly }) => {
    const { token,isAuth } = useContext(AuthContext);
  
    
    useEffect(() => {
        console.log('hhhhhhh',isAuth);
    }, [])
    
    
    if (token === null) {
        // Render a loading indicator or redirect to a loading page
        console.log('4444444');
        return <LoadingIndicator />;
      }

    console.log('111111111111111',token);
  
    if (!token) {
        console.log('qqqqqq');
      return <Navigate to="login" replace />;
    }
  
    return <Component /> ;
  };
  
  export default PrivateRoute;