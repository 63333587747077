import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import { mockDataTeam } from "../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../components/Header";
import AddCompanyModal from "../components/Modals/AddCompanyModal";
import BasicTable from "../components/Tables/BasicTable";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { getRoles } from "@testing-library/react";
import api from "../axios";
import AddAssetModal from "../components/StaffPage/AddStaffModal";
// import AddManagerModal from "../components/StaffPage/AddManager";
// import MainContext from "../contexts/MainContext";
import { useMediaQuery } from "@mui/material";
import { AuthContext } from "../AuthContext";
import StaffBoxNew from "../components/StaffPage/StaffBoxNew";

const StaffPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { token } = useContext(AuthContext);
  const [rowData, setRowData] = useState([]);
  // const { setAssetFilter } = useContext(MainContext);
  const isMobile = useMediaQuery("(max-width : 600px)");

  const getData = () => {
    api
      .get('staff')
      .then((response) => {
        console.log('====================================');
        console.log(response.data);
        console.log('====================================');
        // const staffData = response.data.filter((user) => user.role === "staff");
        // const managerData = response.data.filter(
        //   (user) => user.role === "manager"
        // );
        // setStaffData(staffData);
        // setManagerData(managerData);
        setRowData(response.data)
      })
      .catch((error) => {
        if (error.response.status === 403) {
          window.location.reload();
        }
      });
  };

  const [staffData, setStaffData] = useState([]);
  const [managerData, setManagerData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box sx={{ marginInline: "20px" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Your People" subtitle="Manage your staff and managers" />
        <Box display="flex" direction="row" gap="2px">
          <AddAssetModal type="staff" updateData={getData}></AddAssetModal>
          {/* <AddAssetModal type="manager" updateData={getData}></AddAssetModal> */}
          {/* <AddManagerModal updateData={getData}></AddManagerModal> */}
        </Box>
      </Box>

      {/* <Box maxWidth="70vw">
        <Carousel />
      </Box> */}

      <Box
        borderRadius="10px"
        height={isMobile ? "600px" : "70vh"}
        // marginTop="20px"
        overflow="auto"
      >
        {/* <StaffBox data={data}/> */}
        <StaffBoxNew
          updateData={getData}
          staffData={rowData}
          // managerData={managerData}
        />
      </Box>
    </Box>
  );
};

export default StaffPage;
