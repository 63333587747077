import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import api from "../../axios";
import { AuthContext } from "../../AuthContext";
import { LocalGasStation } from "@mui/icons-material";
import { toast } from "react-toastify";

// import { toast } from "react-toastify";

const DieselPriceModal = () => {
  const [open, setOpen] = useState(false);

  // const [location2,setLocation2] = useState('');
  const { selectedCompany, getRows } = useContext(AuthContext);
  const company_id = selectedCompany.id;
  const currentDieselPrice = selectedCompany?.company_info?.diesel_price;
  const currentDiscountPrice = selectedCompany?.company_info?.discount_price;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setEditingDiesel(false);
    setOpen(false);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    // if (open === true){
    //   getLocations()
    // }
  }, [open]);

  // const getLocations = async () => {
  //   await api.get(`location/${company_id}`).then((response)=>{
  //     setData(response.data)
  //   })
  // }

  const [editingDiesel, setEditingDiesel] = useState(false);
  const [editingDiscount, setEditingDiscount] = useState(false);
  const [dieselPrice, setDieselPrice] = useState(currentDieselPrice);
  const [discountPrice, setDiscountPrice] = useState(currentDiscountPrice);

  const handleChange = (data, type) => {
    // console.log('vvvv',data,type);
    if (type === "diesel") {
      setDieselPrice(data);
    } else {
      setDiscountPrice(data);
    }
  };

  const handleEditMode = (type) => {
    if (type === "diesel") {
      setEditingDiesel(true);
    } else {
      setEditingDiscount(true);
    }
  };

  const handleApply = (type) => {
    // Perform any desired actions with the current price
    if (type === "diesel") {
      if (currentDieselPrice !== dieselPrice) {
        api
          .put("fuel-price", { diesel_Price:dieselPrice, company_id, change_type: type })
          .then((response) => {
            getRows();
            toast.success(response.data);
          })
          .catch((error) => {
            toast.error("Something Went Wrong..!!!");
          });
      }
      setEditingDiesel(false);
    } else {
      if (currentDiscountPrice !== discountPrice) {
        api
          .put("fuel-price", { discount_price:discountPrice, company_id, change_type: type })
          .then((response) => {
            getRows();
            toast.success(response.data);
          })
          .catch((error) => {
            toast.error("Something Went Wrong..!!!");
          });
      }
      setEditingDiscount(false);
    }
  };

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open Modal</Button> */}
      <div
        onClick={() => handleOpen()}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <ListItemIcon>
          <LocalGasStation />
        </ListItemIcon>
        <ListItemText secondary="Fuel Price" />
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            minWidth: 400,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{ textAlign: "center" }}
              variant="h3"
              id="modal-title"
              gutterBottom
              sx={{ mb: 3 }}
            >
              Current Diesel & Discount Price
            </Typography>
            {/* Display added locations here */}

            <Typography
              sx={{ textAlign: "center" }}
              variant="h3"
              id="modal-title"
              gutterBottom
            >
              <LocalGasStation sx={{ fontSize: "100px" }} />
            </Typography>
          </Box>

          <Box display="flex" gap="10px" flexDirection="row">
            <Box
              sx={{
                borderRadius: "4px",
                width: "100%",
                color: "black",
              }}
            >
              <TextField
                label="Diesel Price"
                value={dieselPrice}
                onChange={(e) => handleChange(e.target.value, "diesel")}
                fullWidth
                disabled={!editingDiesel}
              />
            </Box>

            {editingDiesel ? (
              <>
                <Button
                  sx={{ bgcolor: "#13579c" }}
                  variant="contained"
                  onClick={() => handleApply("diesel")}
                >
                  Apply
                </Button>
              </>
            ) : (
              <Button
                sx={{ bgcolor: "#13579c" }}
                variant="contained"
                onClick={() => handleEditMode("diesel")}
              >
                Edit
              </Button>
            )}
          </Box>

          <Box sx={{ mt: 2 }} display="flex" gap="10px" flexDirection="row">
            <Box
              sx={{
                borderRadius: "4px",
                width: "100%",
                color: "black",
              }}
            >
              <TextField
                label="Discount Price"
                value={discountPrice}
                onChange={(e) => handleChange(e.target.value, "discount")}
                fullWidth
                disabled={!editingDiscount}
              />
            </Box>

            {editingDiscount ? (
              <>
                <Button
                  sx={{ bgcolor: "#13579c" }}
                  variant="contained"
                  onClick={() => handleApply("discount")}
                >
                  Apply
                </Button>
              </>
            ) : (
              <Button
                sx={{ bgcolor: "#13579c" }}
                variant="contained"
                onClick={() => handleEditMode("discount")}
              >
                Edit
              </Button>
            )}
          </Box>

          <Button
            sx={{ bgcolor: "firebrick", mt:2}}
            variant="contained"
            onClick={handleClose}
          >
            Close
          </Button>

          {/* Add location section */}
        </Box>
      </Modal>
    </div>
  );
};

export default DieselPriceModal;
