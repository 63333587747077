import React from 'react';

export const CustomToast = ({ keys, values }) => {
    console.log('hellll');
  return (
    <div>
         <h1 style={{color:'red'}}>Adding Company Failed</h1>
         <h6>Submit the form again by </h6>
      {keys.map((key, index) => (
        <div key={index} style={{background:'lightgrey',marginBottom:'2px',borderRadius:'2px',textAlign:'center'}}>           
          <span style={{ fontWeight: 'bold', color: 'black',fontSize:'1rem' }}>{key} </span><br></br>
          <span style={{ color: 'red' }}>{values[index]}</span>
        </div>
      ))}
    </div>
  );
};
