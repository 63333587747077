import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import LoadingIndicator from "./components/LoadingIndicator";
// import { Navigate } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import api from "./axios";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  //   const [isAdmin, setIsAdmin] = useState(localStorage.getItem('isAdmin') || false);
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [updateOrder, setUpdateOrder] = useState(false);
  const [tableRow, setTableRow] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const navigate = useNavigate();

  const [rowData,setRowData] = useState([])

  const getRows = async () => {
   await api.get('company').then((response)=>{
      console.log(response.data);
      setRowData(response.data)
    }).catch((error)=>{
      if (error.response.status === 403){
        window.location.reload()
      }
    })
  }


  const checkAuth = async () => {
    try {
      // Make an API call to the backend to check authentication status and retrieve token and user info
      await api.get("checkauth").then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          setToken(response.data.user);
          setIsAuth(true);
          const lastPath = window.location.pathname;

          navigate(lastPath);

          // navigate('/admin')
        } else {
          console.log("lllllo");
        }
      });
    } catch (error) {
      console.error("Failed to check authentication:", error);
      if (error.code === "ERR_NETWORK") {
        navigate("/serverdown");
      } else {
        navigate("/login");
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    checkAuth();
  }, [isLoading]);

  //   if (token === null) {
  //     // Render a loading indicator or redirect to a loading page
  //     return <LoadingIndicator />;
  //   }


  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        isAuth,
        selectedCompany,
        setSelectedCompany,
        updateOrder,
        setUpdateOrder,
        getRows,
        rowData,
        setRowData
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
