import axios from 'axios'
import React from 'react'

function UnderDevad() {
  return (
    <div onClick={()=>{
        axios.get('http://localhost:8000/checkauth',{ withCredentials: true }).then((response)=>{
            console.log(response.data);
        })
    }} 
    style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"100px"}}
    >Still Under Development Admin Page</div>
  )
}

export default UnderDevad