import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Modal, Box, TextField, FormControl, Grid, InputLabel, Input, Button, useMediaQuery, useTheme, ListItemIcon, ListItemText } from '@mui/material';
import { tokens } from '../../theme';
import CloseIcon from '@mui/icons-material/Close';
import { useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import { Info, LocationOn } from '@mui/icons-material';


export default function CompanyDetailModal() {
    const theme = useTheme();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const colors = tokens(theme.palette.mode);
    const {selectedCompany} = useContext(AuthContext)
    // const { selectedCompany } = useContext(AuthContext)

    const data = selectedCompany
    console.log(selectedCompany,'3333333333333');
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80vw',
        maxHeight: '70vh',
        bgcolor: colors.blueAccent[900],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'auto'
    };


    return (
      <div>
        {/* <Button onClick={handleOpen}>Open modal</Button> */}

        {/* <Button onClick={() => handleOpen()}  sx={{
                color: 'darkgrey',
                
                ...(isMobile && {
                    minWidth: 30,
                    padding: '4px 8px',
                    fontSize: 11,
                }),
            }}>
                Details
            </Button> */}

        <div
          onClick={() => handleOpen()}
          style={{ display: "flex", flexDirection: "row" }}
        >
          <ListItemIcon>
            <Info />
          </ListItemIcon>
          <ListItemText secondary="Details" />
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography variant="h3" align="center">
              Company Details
            </Typography>
            <Box
              sx={{
                position: "absolute",
                top: 20,
                right: 20,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography
                variant="body1"
                sx={{ marginLeft: 1, cursor: "pointer" }}
                onClick={handleClose}
              >
                Close
              </Typography>
              <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
            </Box>

            <Box
              sx={{ display: "flex", flexWrap: "wrap", marginBottom: 5, mt: 3 }}
            >
              <Box
                sx={{
                  width: "100%",
                  [theme.breakpoints.up("sm")]: {
                    width: "50%",
                    paddingRight: 8,
                  },
                }}
              >
                <Typography variant="subtitle1">General Information</Typography>

                <FormControl fullWidth margin="normal">
                  <TextField
                    id="filled-read-only-input"
                    label="Username"
                    defaultValue={data.username}
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="filled"
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="filled-read-only-input"
                    label="E-mail"
                    defaultValue={data.email}
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="filled"
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="filled-read-only-input"
                    label="Phone Number"
                    defaultValue={data.number}
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="filled"
                  />
                </FormControl>
                {/* Add more general information fields as needed */}
              </Box>
              <Box
                sx={{
                  width: "100%",
                  mt: 5,
                  [theme.breakpoints.up("sm")]: {
                    width: "50%",
                    paddingLeft: 8,
                    mt: 0,
                  },
                }}
              >
                <Typography variant="subtitle1">Trade Information</Typography>

                <FormControl fullWidth margin="normal">
                  <TextField
                    id="filled-read-only-input"
                    label="Trade name"
                    defaultValue={data.company_info?.trade_name}
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="filled"
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <TextField
                    id="filled-read-only-input"
                    label="Administrative Office"
                    defaultValue={data.company_info?.administrative_office}
                    InputProps={{
                      readOnly: true,
                    }}
                    multiline
                    variant="filled"
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <TextField
                    id="filled-read-only-input"
                    label="Other Office"
                    defaultValue={data.company_info?.other_office}
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="filled"
                    multiline
                  />
                </FormControl>

                {/* Add more trade information fields as needed */}
              </Box>
            </Box>
          </Box>
        </Modal>
      </div>
    );
}