import React, { useState } from "react";
import { Modal, Box } from "@mui/material";
import {
  CheckIcon,
  XCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/20/solid";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import api from "../../axios";
import { toast } from "react-toastify";

const ApproveOrderModal = ({ req_qty,orderId }) => {
  const [open, setOpen] = useState(false);
  const [approvedQuantity, setApprovedQuantity] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your logic to handle the form submission here
    // For example, you can make an API call to submit the approvedQuantity
    // using the 'api' object from Axios.
    // api.post("/submit", { approvedQuantity });
    api.put(`change-orderstatus/${orderId}`,
    {order_status:'Approved','approved_qty':approvedQuantity}
    ).then((response)=>{
       toast.success(response.data)
    }).catch((error)=>{
        toast.error(error.response.data)

    }).finally(()=>{
        window.location.reload();
    })
    handleClose();
  };

  return (
    <div>
      <span className="">
        <button
          onClick={handleOpen}
          type="button"
          className="inline-flex items-center w-full rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <CheckIcon className="-ml-1 mr-2 h-6 w-6" aria-hidden="true" />
          Approve
        </button>
      </span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 6,
            minWidth: 400,
            borderRadius: "0.5rem",
          }}
        >
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold">Complete Delivery</h2>
            <button onClick={handleClose}>
              <XCircleIcon
                className="h-6 w-6 text-gray-500"
                aria-hidden="true"
              />
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <div className="flex items-center mb-2">
                <ExclamationCircleIcon className="h-5 w-5 mr-2 text-indigo-600" />
                <span className="text-sm font-medium text-gray-700">
                  Requested Quantity : {req_qty} (Ltrs)
                </span>
              </div>
              <label
                htmlFor="approvedQuantity"
                className="block text-sm font-medium text-gray-700"
              >
                Approved Quantity
              </label>
              <input
                type="text"
                id="approvedQuantity"
                name="approvedQuantity"
                className="mt-1 p-2 border focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-base border-gray-300 rounded-md"
                placeholder="Quantity in Ltrs"
                value={approvedQuantity}
                onChange={(e) => setApprovedQuantity(e.target.value)}
              />
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                className="mr-2 px-4 py-2 text-sm font-medium text-gray-500 hover:text-gray-700"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <CheckIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Submit
              </button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default ApproveOrderModal;
