import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  ListItemIcon,
  ListItemText,
  ToggleButtonGroup,
  ToggleButton,
  useMediaQuery
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import api from "../../axios";
import { toast } from "react-toastify";
import OrderBox from "./OrdersBox";
import { LocalShipping } from "@mui/icons-material";
import AddOrderModal from "./AddOrderModal";
import HistoryBox from "../Tables/OrdersTable";

const OrdersModal = ({ company }) => {
  const [open, setOpen] = useState(false);
  const [location, setLocation] = useState("");
  // const [location2,setLocation2] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [data, setData] = useState([]);

  const [section, setSection] = useState("client");
  const isMobile = useMediaQuery("(max-width : 600px)");

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open Modal</Button> */}
      <div
        onClick={handleOpen}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <ListItemIcon>
          <LocalShipping />
        </ListItemIcon>
        <ListItemText secondary="Orders" />
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: isMobile?'90vw':'70vw',
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <ToggleButtonGroup
              color="primary"
              value={section}
              exclusive
              onChange={(e) => setSection(e.target.value)}
              aria-label="Platform"
            >
              <ToggleButton value="client">Client</ToggleButton>
              <ToggleButton value="romulus">Romulus</ToggleButton>
            </ToggleButtonGroup>

            <AddOrderModal />
          </Box>

          {section === "client" ? (
            // <OrderBox type='client' company={company}/>
            <HistoryBox fetch_type='single' order_type='client' />
          ) : (
            <HistoryBox fetch_type='single' order_type='romulus' />
          )}
          {/* <HistoryBox order_type={section} /> */}
        </Box>
      </Modal>
    </div>
  );
};

export default OrdersModal;
