import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Pagination,
  useTheme,
  useMediaQuery,
  Typography,
  Box,
  FormControl,
  InputLabel,
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import api from "../../axios";
import { tokens } from "../../theme";

import { AuthContext } from "../../AuthContext";

import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import {
  LocalShipping,
  FormatListBulleted,
  Description,
  Opacity,
  Payment,
  Download,
  Done,
  Cancel,
} from "@mui/icons-material";
import {
  Popover,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { toast } from "react-toastify";
import AddDeliveryModal from "../OrdersPage/DeliveryModal";
import { useNavigate } from "react-router-dom";

const OrdersTable = ({ fetch_type, order_type }) => {
  const [openDelivery, setOpenDelivery] = useState(false);
  const [orders, setOrders] = useState([]);
  const [orderStatus, setOrderStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { token } = useContext(AuthContext);

  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [openPopover, setOpenPopover] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedOrder, setSelectedOrder] = useState();

  const handleOpenPopover = (event, id) => {
    console.log(id);
    setSelectedOrder(id);
    setPopoverAnchor(event.currentTarget);
    setOpenPopover(true);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    console.log(status);
  };

  const handleCloseDelivery = () => {
    setOpenDelivery(false);
  };
  const handleApplyStatus = () => {
    // Apply the selected status
    // You can add your logic here
    if (selectedStatus === "Delivered") {
      setOpenDelivery(true);
    } else {
      api
        .post(`change-orderstatus/${selectedOrder}`, {
          order_status: selectedStatus,
        })
        .then((response) => {
          fetchOrders();
        });
    }

    // Close the popover
    handleClosePopover();
  };

  const isMobile = useMediaQuery("(max-width : 600px)");
  useEffect(() => {
    fetchOrders();
  }, [orderStatus, currentPage, order_type]);

  let dummyIcon = require("../../assets/datum.png");

  const { selectedCompany } = useContext(AuthContext);
  const comp_id = selectedCompany?.id;

  const endpoint = fetch_type === "single" ? "order-history/" : "all-orders";
  const fetchOrders = async () => {
    console.log(token);
    try {
      const response = await api.get(endpoint, {
        params: {
          order_type: order_type,
          order_status: orderStatus,
          page: currentPage,
          company_id: fetch_type === "single" ? comp_id : "",
        },
      });
      console.log("121221", response.data);
      const { results, current_page, count } = response.data;
      setOrders(results);
      setTotalPages(parseInt(count / 12) + 1);
    } catch (error) {
      if (error.response.status === 403) {
        window.location.reload();
      }
    }
  };

  const handleFilterChange = (status) => {
    setOrderStatus(status);
    setCurrentPage(1);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    console.log(totalPages);
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleString("en-US", options);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMethod, setSelectedMethod] = useState("dateRange");
  const [selectedMonth, setSelectedMonth] = useState("June");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = () => {
    // if (!selectedMethod) {
    //   return;
    // }

    if (selectedMethod === "dateRange") {
      if (!fromDate || !toDate) {
        toast.error("Please select from and to date.");
        return;
      }

      if (fromDate >= toDate) {
        toast.error("From date should be earlier than to date");
        return;
      }
    }

    let params = {};

    if (selectedMethod === "dateRange") {
      params = {
        company_id: token.company_id ? token.company_id : token.id,
        from_date: fromDate,
        to_date: toDate,
        filter_type: "date_range",
      };
    } else if (selectedMethod === "monthly") {
      params = {
        company_id: token.company_id ? token.company_id : token.id,
        month: selectedMonth,
        filter_type: "month",
      };
    }

    api
      .get("export-orders/", {
        params,
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "orders.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Error downloading orders:", error);
      });
  };

  const handleMethodChange = (event) => {
    setSelectedMethod(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const open = Boolean(anchorEl);
  const id = open ? "download-popover" : undefined;

  const assetIcons = {
    datum: require("../../assets/datum.png"),
    truck: require("../../assets/truck.png"),
    InfraMachine: require("../../assets/citytech-logo.png"),
    DGset: require("../../assets/generator.png"),
    Industrial: require("../../assets/factory.png"),
    others: require("../../assets/oil-pump.png"),
  };

  const navigate = useNavigate()
  
  return (
    <div>
      <Box display="flex" justifyContent="space-between" spacing={1}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography textAlign="center">Filter :</Typography>
          <Button
            sx={{
              bgcolor: colors.card["bg"],
              color: colors.pure[900],
              "&:hover": {
                backgroundColor: colors.card["hover"],
              },
            }}
            onClick={() => handleFilterChange("")}
            startIcon={<LocalShipping />}
          >
            Asset type
          </Button>

          <Button
            sx={{
              ml: 1,
              bgcolor: colors.card["bg"],
              color: colors.pure[900],
              "&:hover": {
                backgroundColor: colors.card["hover"],
              },
            }}
            onClick={() => handleFilterChange("")}
            startIcon={<FormatListBulleted />}
          >
            Date
          </Button>
        </Box>

        <Box display="flex" gap="1px">
          <div>
            <Button
              sx={{
                bgcolor: colors.card["bg"],
                color: colors.pure[900],
                "&:hover": {
                  backgroundColor: colors.card["hover"],
                },
              }}
              onClick={() => handleFilterChange("")}
              startIcon={<Description />}
              onClick={handleClick}
            >
              Export to XLS
            </Button>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Box padding="5px" display="flex" flexDirection="column">
                <Box display="flex" flexDirection="row">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedMethod === "dateRange"}
                        onChange={handleMethodChange}
                        value="dateRange"
                      />
                    }
                    label="Date Range"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedMethod === "monthly"}
                        onChange={handleMethodChange}
                        value="monthly"
                      />
                    }
                    label="Monthly"
                  />
                </Box>

                <Box>
                  {selectedMethod === "dateRange" && (
                    <div>
                      <input
                        type="date"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                      />
                      <input
                        type="date"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                      />
                    </div>
                  )}
                  {selectedMethod === "monthly" && (
                    <FormControl fullWidth>
                      <Select
                        value={selectedMonth}
                        onChange={handleMonthChange}
                      >
                        <MenuItem value="January">January</MenuItem>
                        <MenuItem value="February">February</MenuItem>
                        <MenuItem value="March">March</MenuItem>
                        <MenuItem value="April">April</MenuItem>
                        <MenuItem value="May">May</MenuItem>
                        <MenuItem value="June">June</MenuItem>
                        <MenuItem value="July">July</MenuItem>
                        <MenuItem value="August">August</MenuItem>
                        <MenuItem value="September">September</MenuItem>
                        <MenuItem value="October">October</MenuItem>
                        <MenuItem value="November">November</MenuItem>
                        <MenuItem value="December">December</MenuItem>

                        {/* Add more months */}
                      </Select>
                    </FormControl>
                  )}
                </Box>

                <Button startIcon={<Download />} onClick={handleDownload}>
                  Download XLS
                </Button>
              </Box>
            </Popover>
          </div>

          {/* <Button
            sx={{
              bgcolor: colors.card["bg"],
              color: colors.pure[900],
              "&:hover": {
                backgroundColor: colors.card["hover"],
              },
            }}
            onClick={() => handleFilterChange("")}
            startIcon={<Description />}
          >
            Export to PDF
          </Button> */}
        </Box>
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          height: "57vh",
          marginBlock: 2,
          pt: 0,
          overflow: "auto",
          bgcolor: colors.card2["bg"],
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {/* <TableCell style={{ fontWeight: "600" }}>Asset</TableCell> */}
              <TableCell style={{ fontWeight: "600" }}>Order ID</TableCell>
              <TableCell style={{ fontWeight: "600" }}>Ordered By</TableCell>
              <TableCell style={{ fontWeight: "600" }}>Date</TableCell>
              <TableCell>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Opacity sx={{ marginRight: "5px" }} />
                  <span style={{ fontWeight: "600" }}>Req.Quantity (Ltrs)</span>
                </Box>
              </TableCell>
              <TableCell>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Payment sx={{ marginRight: "5px" }} />
                  <span style={{ fontWeight: "600" }}>Req.Total</span>
                </Box>
              </TableCell>

              <TableCell>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {/* <Payment sx={{ marginRight: "5px" }} /> */}
                  <span style={{ fontWeight: "600" }}>Status</span>
                </Box>
              </TableCell>

              <TableCell style={{ fontWeight: "600" }}>Details</TableCell>
              {/* <TableCell style={{ fontWeight: "600" }}>Status</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => {
              let assetIcon;
              if (order.asset_type === "Datum") {
                assetIcon = assetIcons["datum"];
              } else if (order.asset_type === "Heavy vehicle") {
                assetIcon = assetIcons["truck"];
              } else if (order.asset_type === "Infra Machine") {
                assetIcon = assetIcons["InfraMachine"];
              } else if (order.asset_type === "DG set") {
                assetIcon = assetIcons["DGset"];
              } else if (order.asset_type === "Industrial") {
                assetIcon = assetIcons["Industrial"];
              } else if (order.asset_type === "Others") {
                assetIcon = assetIcons["others"];
              } else {
                assetIcon = assetIcons["others"];
              }
              return (
                <TableRow key={order.id}>
                  {/* <TableCell>
                    <Box display="flex" alignItems="center">
                      <img
                        src={assetIcon}
                        alt={order.asset.name}
                        style={{
                          width: "22px",
                          height: "22px",
                          marginRight: "10px",
                          filter: colors.pngcol["filter"],
                        }}
                      />
                      <Box display="flex" flexDirection="column">
                        <Typography fontWeight="700">
                          {order.asset_type === "Heavy vehicle"
                            ? order.asset_reg
                            : order.asset_name}
                        </Typography>
                        <Typography fontSize="12px">
                          {order.asset_type}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell> */}
                  <TableCell>
                    <Typography fontWeight="600">
                      <span style={{ fontWeight: "700", fontSize: "15px" }}>
                        {order.order_id}
                      </span>
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography fontWeight="600">
                      <span style={{ fontWeight: "700", fontSize: "15px" }}>
                        {order.company_name}
                      </span>
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography fontWeight="600">
                      {formatDate(order.created_at)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        fontWeight: "700",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {/* <Opacity style={{ fontWeight: "600", fontSize: "20px" }} /> */}
                      {order.requested_quantity} Ltrs
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        fontWeight: "700",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      ₹ {order.requested_total_price}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <span className="text-green-700 font-bold">
                      {order.order_status}
                    </span>
                  </TableCell>

                  <TableCell>
                    <Button
                      onClick={() => {
                        navigate(`/order-details/${order.id}`);
                      }}
                      variant="contained"
                    >
                      Details
                    </Button>
                  </TableCell>

                  {/* 
                  <TableCell
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "700",
                        display: "flex",
                        alignItems: "center",
                        color:
                          order.order_status === "Approved"
                            ? "orange"
                            : order.order_status === "Cancelled"
                            ? "firebrick"
                            : order.order_status === "ordered"
                            ? "green"
                            : order.order_status === "Delivered"
                            ? "#1476c2"
                            : "inherit",
                      }}
                    >
                      {order.order_status}
                    </Typography>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={(e) => handleOpenPopover(e, order.id)}
                    >
                      Change
                    </Button>
                    <Popover
                      // style={{ boxShadow: "none" }}
                      PaperProps={{
                        style: { boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" },
                      }}
                      open={openPopover}
                      anchorEl={popoverAnchor}
                      onClose={handleClosePopover}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <List sx={{ p: 1 }}>
                        {["Approved", "Cancelled", "Delivered"].map(
                          (status, index) => (
                            <ListItem
                              key={index}
                              button
                              selected={selectedStatus === status}
                              onClick={() => handleStatusChange(status)}
                            >
                              <ListItemIcon>
                                {status === "Approved" && <Done />}
                                {status === "Cancelled" && <Cancel />}
                                {status === "Delivered" && <LocalShipping />}
                              </ListItemIcon>
                              <ListItemText
                                primary={status}
                                //  sx={
                                //    selectedStatus === status
                                //      ? { color: "black" }
                                //      : {}
                                //  }
                                sx={{
                                  color:
                                    status === "Approved"
                                      ? "orange"
                                      : status === "Cancelled"
                                      ? "red"
                                      : status === "Delivered"
                                      ? "blue"
                                      : "inherit",
                                }}
                                primary={status}
                              />
                            </ListItem>
                          )
                        )}
                        <Button
                          variant="contained"
                          sx={{ width: "100%", mt: 1 }}
                          onClick={() =>
                            handleApplyStatus(order.requested_quantity)
                          }
                        >
                          Apply
                        </Button>
                      </List>
                    </Popover>
                  </TableCell> */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {openDelivery ? <AddDeliveryModal onClose={handleCloseDelivery} /> : ""}
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        showFirstButton
        showLastButton
        siblingCount={2}
        boundaryCount={2}
      />
    </div>
  );
};

export default OrdersTable;
