import { useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";

const SelectableCheckbox = ({ name, checked, onChange, value, icon,disabled }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const StyledCheckbox = styled(Checkbox)({
    borderRadius: '0px',
    width: isMobile ? '44px' : '64px',
    height: isMobile ? '49px' : '64px',
    fontSize: isMobile ? '10px' : '14px',
  });

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <FormControlLabel
        sx={{m:isMobile?0.5 : 1}}
      control={
        <StyledCheckbox
          sx={{
            bgcolor: checked ? '#3e4396' : colors.grey[900],
            "&:hover":{bgcolor:checked ? '#3e4396':colors.grey[600]},
            borderRadius: isMobile ? '0px' : '4px',
            p: isMobile ? 0 : 1,
            m: isMobile ? 0 : 1
          }}
          name={name}
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          value={value}
          icon={
            <Box display="flex" flexDirection="column" alignItems="center">
              {icon}
              <Typography variant="body2" sx={{ textAlign:'center', fontWeight:'800', fontSize: isMobile ? '9px' : '12px' }}>
                {name}
              </Typography>
            </Box>
          }
          checkedIcon={
            <Box display="flex" flexDirection="column" color='white' alignItems="center">
              {icon}
              <Typography variant="body2" sx={{ color:'white', textAlign:'center',fontSize: isMobile ? '9px' : '12px' }}>
                {name}
              </Typography>
            </Box>
          }
        />
      }
      labelPlacement="top"
    />
  );
};

export default SelectableCheckbox;
