import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, Box, Typography, TextField, ListItemIcon, ListItemText } from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import api from "../../axios";
import { AuthContext } from "../../AuthContext";
// import { toast } from "react-toastify";


const AddLocationModal = ({company}) => {
  const [open, setOpen] = useState(false);
  const [location, setLocation] = useState("");
  // const [location2,setLocation2] = useState('');
  const { selectedCompany } = useContext(AuthContext)
  const company_id = selectedCompany.id
  console.log('tttttttttt',company_id);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };


  const handleAddLocation = () => {
    // Add location logic here

    api.post('location',{company:company_id,location}).then((response)=>{
      console.log(response.data);

      getLocations()
    })
    setLocation("");
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [data, setData] = useState([])

  useEffect(() => {
    if (open === true){
      getLocations()
    }
   }, [open])

  const getLocations = async () => {
    await api.get(`location/${company_id}`).then((response)=>{
      setData(response.data)
    })
  }

  const handleDelete = (id) => {
    api.delete(`location/${id}`).then((response)=>{
      getLocations()
    })
  }

 
  

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open Modal</Button> */}
      <div onClick={()=>handleOpen()} style={{display:'flex',flexDirection:'row'}}> 

      <ListItemIcon><LocationOnIcon/></ListItemIcon>
      <ListItemText secondary='Locations' />
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            minWidth: 400,
            maxHeight:'70vh',
            overflow:'auto'
          }}
        >
          <Typography
            sx={{ textAlign: "center" }}
            variant="h3"
            id="modal-title"
            gutterBottom
          >
            Asset Locations
          </Typography>
          {/* Display added locations here */}

          <Typography variant="h5" id="modal-description" gutterBottom>
            Current Locations
          </Typography>



          <Box display='flex' gap='4px' flexDirection='column'>
            
            {data.map((obj)=>(
              <Box
              sx={{
                
                // bgcolor: colors.grey[900],
                borderRadius: "4px",
                p: 2,
                color: "black",
                borderWidth: '1px',
                borderColor: 'darkgray',
                borderStyle: 'solid',
              }}
            >
              <Typography sx={{color:colors.grey[100]}}>Location &nbsp;:&nbsp;</Typography>
              <Typography>
                {obj.location}
              </Typography>
              <Button variant='contained' onClick={()=>handleDelete(obj.id)}>Delete</Button>
            </Box>           
            ))

              }
            </Box>
            

          {/* Add location section */}

          <Typography sx={{ mt: 3 }} variant="subtitle1" gutterBottom>
            Add Location
          </Typography>

          <TextField
            label="Location Detail"
            value={location}
            onChange={handleLocationChange}
            fullWidth
          />
          <Button sx={{mt:2}} variant="contained" onClick={handleAddLocation}>
            Add
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default AddLocationModal;
