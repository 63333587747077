import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import api from '../../axios'
import { useContext,useEffect } from 'react';
import { AuthContext } from '../../AuthContext';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import LoadingIndicator from '../../components/LoadingIndicator';
import { toast } from 'react-toastify';
import axios from 'axios'
import { adminBaseurl } from '../../constants';
import { loginApi } from '../../axios';
import ProgressCircle from '../../components/ProgressCircle';

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn() {
    
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const data = new FormData(event.currentTarget);
    const username = data.get('username')
    const password = data.get('password')
    const details ={username,password};
    localStorage.setItem('selected','Dashboard')
    
    try{
        await api.post(`login`,details,{ withCredentials: true,skipInterceptor:true}).then((response)=>{
            console.log(response);
            if (response.status == 200){
                console.log('sssssss',response.data.user);
                window.location.reload();
                
            }
          
        })
    }catch(error) {
        if (error.response && error.response.status === 400){
          toast.error('Invalid Credentials')
          setIsLoading(false)
        }else{
          toast.error('Something Went Wrong..!! Please try again')
          setIsLoading(false)
        }
    }
  };

  const {isAuth} = useContext(AuthContext)
  const navigate = useNavigate()
  const [isLoading,setIsLoading] = useState(true)

//   setIsLoading(true)

  useEffect(() => {
    // setIsLoading(true)
    if (isAuth==true){
      navigate('/')
    }
    setIsLoading(false)
  }, [isAuth])

  if (isLoading === true){
    return(
      <Box>

        <ProgressCircle/>
      </Box>
    )
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
            
          </Avatar>

          <Typography component="h1" variant="h5">
           Admin
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
            // onClick={handleSubmit}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                {/* <Link href="#" variant="body2">
                  Forgot password?
                </Link> */}
              </Grid>
              <Grid item>
                {/* <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link> */}
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      </Container>
    </ThemeProvider>
  );
}