import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  ListItemIcon,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import api from "../../axios";
import { toast } from "react-toastify";
import OrderBox from "./OrdersBox";
import { LocalShipping, Payments } from "@mui/icons-material";
import TransactionBox from "./TransactionBox";

const AddPaymentModal = ({ updateTable, company, fetchPayments }) => {
  const [open, setOpen] = useState(false);
  const [location, setLocation] = useState("");
  // const [location2,setLocation2] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [data, setData] = useState([]);
  const isMobile = useMediaQuery("(max-width: 600px)");

  const [paymentType, setPaymentType] = useState("");
  const [amount, setAmount] = useState("");
  const [transactionId, setTransactionId] = useState("");

  const handleSubmit = () => {
    const details = {
      company: company,
      payment_method: paymentType,
      payment_price: amount,
      transaction_id: paymentType==='online'?transactionId:'',
      payment_type:'paid'
    };
    api.post('add-payment',details).then((response)=>{
        toast.success(response.data)
        fetchPayments()
        updateTable()
        handleClose()
    }).catch((error)=>{
        toast.error(error.response.data)
        console.log('lool',error.response.data);
    })
    console.log("tttttttt", details);
  };

  return (
    <div>
      <Button variant="contained" size="large" onClick={handleOpen}>
        Add Payment
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            minWidth: isMobile ? 400 : 500,
          }}
        >
          <Box display="flex" gap="14px" flexDirection={"column"}>
            <Typography variant="h3">Add Payment</Typography>

            <TextField
              id="outlined-number"
              label="Amount"
              type="number"
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Payment Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={paymentType}
                label="Age"
                onChange={(e) => setPaymentType(e.target.value)}
              >
                <MenuItem value={"cash"}>Cash</MenuItem>
                <MenuItem value={"online"}>Online</MenuItem>
                {/* <MenuItem value={30}>Thirty</MenuItem> */}
              </Select>
            </FormControl>

            {paymentType === "online" ? (
              <TextField
                id="transaction-id"
                label="Transaction ID"
                variant="outlined"
                value={transactionId}
                onChange={(e) => {
                  setTransactionId(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            ) : (
              ""
            )}

            <Button onClick={handleSubmit} variant="contained">
              Add
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default AddPaymentModal;
