import React, { useState } from "react";
import {
  Modal,
  Backdrop,
  Fade,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  ListItemText,
} from "@mui/material";
import { toast } from "react-toastify";
import { useEffect } from "react";
import api from "../../axios";
import { Info } from "@mui/icons-material";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";




const AddPaymentModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState('');
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [partialSelection, setPartialSelection] = useState("");
const [selectedDateTime, setSelectedDateTime] = useState(null);
const [transactionId,setTransactionId] = useState('')

  const handleCompanySelect = (event) => {
    setSelectedCompany(event.target.value);
        setSelectedInvoices([]);
        // setPaymentAmount("");
        setPartialSelection("");
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setSelectedInvoices([]);
    setPaymentAmount('');
    setPartialSelection("");
  };

  const handlePaymentAmountChange = (event) => {
    const amount = event.target.value;
    setPaymentAmount(amount);


  };

  

  const handleInvoiceSelect = (event) => {
    const selectedInvoices = event.target.value;
    let selectedAmount = selectedInvoices.reduce(
      (total, invoice) => total + invoice.pending_amount,
      0
    );

    while (selectedAmount > paymentAmount) {
      selectedInvoices.pop();
      selectedAmount = selectedInvoices.reduce(
        (total, invoice) => total + invoice.pending_amount,
        0
      );
    }
    setPartialSelection("");
    setSelectedInvoices(selectedInvoices);
    // calculateLiveBalance(); 
  };

  const handleRemoveInvoice = (invoiceToRemove) => {
    setSelectedInvoices((prevSelectedInvoices) =>
      prevSelectedInvoices.filter((invoice) => invoice !== invoiceToRemove)
    );
  };

  const [partialAmount, setPartialAmount] = useState();

  const handlePartialSelectionSelect = (event) => {
    const invoice = event.target.value;
    setPartialSelection(invoice);

    if (invoice.pending_amount > liveBalance) {
      setPartialAmount(liveBalance);
      setLiveBalance(0)
    }
  };

  const [invoiceData, setInvoiceData] = useState([]);



  const calculateLiveBalance = () => {
    const totalSelectedAmount = selectedInvoices.reduce(
      (total, invoice) => total + invoice.pending_amount,
      0
    );
    setLiveBalance(paymentAmount - totalSelectedAmount);
  };

  // Calculate the unselected invoices (invoices that are not yet selected)
  const unselectedInvoices = invoiceData.filter(
    (invoice) => !selectedInvoices.includes(invoice)
  );

const handleSubmit = () => {
  if (liveBalance === 0) {

    const formatted_date = selectedDateTime?.format("YYYY-MM-DD HH:mm:ss");

    const details = {
      company: selectedCompany,
      payment_amount:paymentAmount,
      payment_datetime: formatted_date,
      payment_method:paymentMode,
      transaction_id:transactionId,
      full_payment_invoices: selectedInvoices.map((obj) => obj.id),
      partial_payment_invoice: partialSelection ? partialSelection.id : null,
      partial_amount: partialAmount,
    };

    console.log('====================================');
    console.log(details);
    console.log('====================================');

    api.post('add-payment-invoice',details).then((response)=>{
        console.log('====================================');
        console.log(response.data);
        console.log('====================================');
    }).catch((error)=>{
        toast.error(error.response.data)
    })
    // handleClose();
    window.location.reload()
  } else {
    // Show an error message or prevent form submission
    toast.error("Remaining balance should be 0. Please Adjust the Amount Based on that.");
  }

};


const [companyData, setCompanyData] = useState([]);
const [selectedCompany, setSelectedCompany] = useState('');

const [paymentMode, setPaymentMode] = useState("");

const getCompany = () => {
    api.get('get-invoice-company').then((response)=>{
        console.log('=dsfvdsvdsvdsv==================');
        console.log(response.data);
        console.log('====================================');
        setCompanyData(response.data)
    })
}

const getInvoice = () => {
 api.get(`get-invoice-unpaid/${selectedCompany}`).then((response)=>{
    setInvoiceData(response.data)    
    console.log('====================================');
    console.log(response.data);
    console.log('====================================');
})
}

 const handleDateChange = (date) => {
   setSelectedDateTime(date);
 };

const [liveBalance, setLiveBalance] = useState(0);

const removePartialSelection = () => {
    setPartialAmount(null)
    setPartialSelection('')
    calculateLiveBalance()
}



useEffect(() => {
  getCompany()
}, [])

useEffect(()=>{
    getInvoice()
},[selectedCompany])

useEffect(() => {
    calculateLiveBalance();
  }, [paymentAmount, selectedInvoices]);

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Add Payment
      </Button>

      <Modal
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={isOpen}>
          <div className="bg-white p-4 rounded-lg w-[80vw] max-h-[80vh] overflow-auto mx-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <h2 className="text-xl font-bold mb-4">Add Payment</h2>

            <div className="relative mb-2">
              {/* Use react-datetime here */}
              <h5>Select Payment Date and Time</h5>
              <Datetime
                value={selectedDateTime}
                onChange={handleDateChange}
                inputProps={{
                  placeholder: "Select a date and time",
                  className: "rounded-lg px-3 py-2 w-64 text-lg border",
                }}
              />
            </div>

            <div className="mb-4">
              <TextField
                label="Payment Amount"
                type="number"
                value={paymentAmount}
                onChange={handlePaymentAmountChange}
                variant="outlined"
                fullWidth
              />
            </div>

            <div className="mb-4">
              <FormControl fullWidth variant="outlined">
                <InputLabel>Type Of Payment</InputLabel>
                <Select
                  label="Select Transaction Mode"
                  value={paymentMode}
                  onChange={(e) => {
                    setPaymentMode(e.target.value);
                  }}
                  //   renderValue={() => null}
                >
                  {/* <MenuItem value="UPI">UPI</MenuItem> */}
                  <MenuItem value="Online">Online</MenuItem>
                  <MenuItem value="Cash">Cash</MenuItem>
                  <MenuItem value="Cheque">Cheque</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="mb-4">
              <TextField
                label="Transaction Id"
                type="text"
                value={transactionId}
                onChange={(e) => setTransactionId(e.target.value)}
                variant="outlined"
                fullWidth
              />
            </div>

            {/* <FormControl fullWidth variant="outlined">
              <InputLabel>Select Company</InputLabel>
              <Select
                label="Select Company"
                value={selectedCompany}
                onChange={handleCompanySelect}
              >
                {companies.map((company) => (
                  <MenuItem key={company} value={company}>
                    {company}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}

            <div className="mb-4">
              <FormControl fullWidth variant="outlined">
                <InputLabel>Select Company</InputLabel>
                <Select
                  label="Select Company"
                  value={selectedCompany}
                  onChange={handleCompanySelect}
                  //   renderValue={() => null}
                >
                  {companyData.map((company) => (
                    <MenuItem key={company.id} value={company.id}>
                      Company: {company.username}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="mb-4">
              {/* Display the live balance (remaining amount) */}
              {/* <h3 className="text-lg font-bold">Live Balance:</h3> */}
              <div
                className={`text-lg ${
                  liveBalance >= 0 ? "text-green-600" : "text-red-600"
                }`}
              >
                {liveBalance >= 0
                  ? `Remaining Amount: ${liveBalance}`
                  : `Exceeded Amount: ${-liveBalance}`}
              </div>
            </div>

            <div className="mb-4">
              <FormControl fullWidth variant="outlined">
                <InputLabel>Select Invoices</InputLabel>
                <Select
                  label="Select Invoices"
                  multiple
                  value={selectedInvoices}
                  onChange={handleInvoiceSelect}
                  //   renderValue={() => null} // Hide the default renderValue for multiple select
                >
                  {invoiceData.map((invoice) => (
                    <MenuItem key={invoice.invoice_no} value={invoice}>
                      {/* Use inline styles to customize the font size */}
                      <ListItemText
                        primary={`Invoice: ${invoice.invoice_no} - Pending: Rs.${invoice.pending_amount}`}
                        secondary={`Amount: Rs.${invoice.amount} - Location: ${invoice.destination} - Date: ${invoice.invoice_date} - Status: ${invoice.payment_status}`}
                        primaryTypographyProps={{ style: { fontSize: "15px" } }} // Customize primary text font size
                        secondaryTypographyProps={{
                          style: { fontSize: "12px" },
                        }} // Customize secondary text font size
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {liveBalance > 0 && unselectedInvoices.length > 0 && (
              <div className="mb-4">
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Select Invoice for Partial Payment</InputLabel>
                  <Select
                    label="Select Invoices"
                    value={partialSelection}
                    onChange={handlePartialSelectionSelect}
                    // renderValue={() => null}
                  >
                    {/* <MenuItem value="None">None</MenuItem> */}

                    {unselectedInvoices
                      .filter((invoice) => invoice.pending_amount > liveBalance) // Filter invoices with amount > 100
                      .map((invoice) => (
                        <MenuItem key={invoice.invoice_no} value={invoice}>
                          <ListItemText
                            primary={`Invoice: ${invoice.invoice_no} - Pending: Rs.${invoice.pending_amount}`}
                            secondary={`Amount: Rs.${invoice.amount} - Location: ${invoice.destination} - Date: ${invoice.invoice_date} - Status: ${invoice.payment_status}`}
                            primaryTypographyProps={{
                              style: { fontSize: "15px" },
                            }} // Customize primary text font size
                            secondaryTypographyProps={{
                              style: { fontSize: "12px" },
                            }} // Customize secondary text font size
                          />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            )}

            <div className="mb-4">
              {/* Display the selected invoices in a box below */}
              <h3 className="text-lg font-bold">
                Selected Invoices For Full Payment:
              </h3>
              <div className="flex flex-wrap">
                {selectedInvoices.map((invoice) => (
                  <Chip
                    key={invoice.invoice_no}
                    label={`Invoice: ${invoice.invoice_no} - Pending: Rs.${invoice.pending_amount}`}
                    className="m-1"
                    onDelete={() => handleRemoveInvoice(invoice)}
                  />
                ))}
              </div>
            </div>

            {partialSelection && (
              <div className="mb-4">
                <h3 className="text-lg font-bold">
                  Selected Invoice For Partial Payment:
                </h3>
                <div className="flex flex-wrap">
                  <Chip
                    label={`Invoice: ${partialSelection?.invoice_no} - Amount ${partialSelection?.amount}`}
                    className="m-1"
                    onDelete={() => removePartialSelection()}
                  />
                  <span className="ml-2 font-bold mt-1 text-blue-600">
                    <Info /> Remaining Amount (Rs.{partialAmount}) Will be added
                    to this Invoice
                  </span>
                </div>
              </div>
            )}

            <div className="mb-4">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={selectedInvoices.length === 0 && partialSelection == ''}
              >
                Submit
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default AddPaymentModal;
